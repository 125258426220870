import React from 'react';
import { graphql } from 'gatsby';
import ConfirmModale from 'components/pages/ConfirmModale';

const seo = {
  title: 'Q°emotion - Accueil',
  keywords: ['INDEX', 'FR'],
};

export default props =>
  props.location.search === '?formSubmitted=true' ? (
    <ConfirmModale {...props} seo={seo} />
  ) : (
    typeof window !== `undefined` &&
    window.location.replace(`/${props.pageContext.langKey}/whitepaper`)
  );

export const pageQuery = graphql`
  query {
    dataMessage: markdownRemark(
      frontmatter: { pageType: { eq: "confirmWhitepaper" }, lang: { eq: "fr" } }
    ) {
      rawMarkdownBody
    }
  }
`;
